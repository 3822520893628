<template>
  <div>
    <b-alert variant="danger" show>발란 브랜드의 원산지의 매칭 정보를 조회할 수 있는 화면입니다. 브랜드 삭제가 필요한 경우 개발팀으로 업데이트 요청해주세요.</b-alert>
    <b-input-group class="mb-2">
      <b-input-group-prepend>
        <b-button variant="primary" @click="list" :disabled="busy.list">
          <i class="fa fa-search"></i> 검색
          <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
        </b-button>
      </b-input-group-prepend>
      <b-form-input type="text" placeholder="브랜드 번호, 영문 브랜드명, 한글 브랜드명, 영문 원산지, 한글 원산지를 입력해주세요." v-model="form.list.search" @keypress.enter.prevent.stop="list" v-focus></b-form-input>
    </b-input-group>
    <b-row class="mb-2">
      <b-col cols="12" md="6">
        <brand-preset class="mb-2" v-model="form.list.brand"></brand-preset>
      </b-col>
      <b-col cols="6" md="3">
        <div class="pt-2">
          <small>사용여부</small><br/>
          <b-form-radio-group class="col-form-label" v-model="form.list.disabled" :options="[
            {text: '전체', value: 'ALL'},
            {text: '사용', value: false},
            {text: '미사용', value: true}
          ]">
          </b-form-radio-group>
        </div>
      </b-col>
      <b-col cols="6" md="3">
        <div class="pt-2">
          <small>브랜드 속성</small><br/>
          <b-form-radio-group class="col-form-label" v-model="form.list.brand_type" :options="[
              {text: '전체', value: 'ALL'},
              ...brandTypeEnabled.map(e => ({text: $C.BRAND_TYPE_MAP[e].name, value: $C.BRAND_TYPE_MAP[e].type})),
              {text: '입력필요', value: ''}
            ]">
          </b-form-radio-group>
        </div>
      </b-col>
    </b-row>

    <div class="">
      <b-button class="mr-1" variant="primary" @click="list" :disabled="busy.list">
        검색
        <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
      </b-button>
      <b-button class="" variant="success" @click="showAddModal" :disabled="busy.addBrand || !$R('META_BRAND_W')"
                :title="!$R('META_BRAND_W') ? 'META_BRAND_W 권한이 필요합니다' : ''">
        추가
        <b-spinner class="ml-1" small v-if="busy.addBrand"></b-spinner>
      </b-button>
    </div>

    <hr/>

    <div class="clearfix mb-1">
      <div class="pull-left">
      </div>
      <div class="pull-right">
        <xlsx :types="['xlsx']" :preFunc="preDown" :data="xlsx.selected" :labels="xlsx.labels" :keys="xlsx.keys" name="Brands"></xlsx>
        <b-button class="ml-1" variant="outline-success" @click="modal.xlsxUpload = true">Xlsx Upload</b-button>
        <xlsx-upload-modal v-model="modal.xlsxUpload" @refreshList="list"></xlsx-upload-modal>
      </div>
    </div>


    <c-table :table-data="items.list" :fields="fields.list" :perPage.sync="perPage"
             :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list" @btn-clicked="btnAction"
             :caption="items.list.length + ' 개 브랜드'" @get-more="list(true)">
    </c-table>

    <b-modal title="브랜드 상세" size="lg" v-model="modal.detail">
      <template v-slot:modal-title>
        브랜드 상세
        <b-badge class="ml-1 pointer" variant="secondary" @click="copyText(form.detail.brand_no)">{{form.detail.brand_no}} <i class="fa fa-copy ml-1"></i></b-badge>
      </template>

      <div class="label-sm">브랜드명(영문) <span style="color: red">*</span></div>
      <b-input v-model="form.detail.brand_nm" :disabled="busy.brandStat || form.stat.confirmed > 0" placeholder="영문 브랜드명을 대문자로 입력해주세요"></b-input>

      <div class="label-sm">브랜드명(한글) <span style="color: red">*</span></div>
      <b-input v-model="form.detail.brand_nm_kr" :disabled="busy.brandStat || form.stat.confirmed > 0" placeholder="영문 브랜드명을 대문자로 입력해주세요"></b-input>

      <div class="label-sm">브랜드 속성</div>
      <b-form-radio-group class="col-form-label" :disabled="busy.brandStat || (!!selectedItem && !!selectedItem.brand_type)" v-model="form.detail.brand_type" :options="
        brandTypeEnabled.map(e => ({text: $C.BRAND_TYPE_MAP[e].name, value: $C.BRAND_TYPE_MAP[e].type}))
      ">
      </b-form-radio-group>

      <div class="label-sm">원산지 <span style="color: red">*</span></div>
      <b-form-select id="basicSelectDisabled"
        :plain="true"
        :options="[
          { text: '원산지를 선택해주세요.', country_code: null },
          ...this.origins
            .map(i => ({ text: i.country_en, value: i.country_code }))
            .sort((a, b) => a.text < b.text ? -1 : a.text === b.text ? 0 : 1),
          ]"
        v-model="form.detail.country_code">
      </b-form-select>

      <template v-slot:modal-footer="{cancel}">
        <b-button variant="outline-secondary" @click="$modal.show({title: 'JSON 보기', type: 'json', item: selectedItem})">
          JSON
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
        <b-button variant="primary" @click="saveBrand()">
          저장
        </b-button>
      </template>
    </b-modal>

    <b-modal title="브랜드 추가" size="lg" v-model="modal.add">
      <div class="label-sm">브랜드명(영문) <span style="color: red">*</span></div>
      <b-input v-model="form.add.brand_nm" placeholder="영문 브랜드명을 대문자로 입력해주세요"></b-input>

      <div class="label-sm">브랜드명(한글) <span style="color: red">*</span></div>
      <b-input v-model="form.add.brand_nm_kr" placeholder="공식 한글 브랜드명을 입력해주세요"></b-input>

      <div class="label-sm">브랜드 속성</div>
      <b-form-radio-group class="col-form-label" v-model="form.add.brand_type" :options="
        brandTypeEnabled.map(e => ({text: $C.BRAND_TYPE_MAP[e].name, value: $C.BRAND_TYPE_MAP[e].type}))
      ">
      </b-form-radio-group>

      <div class="label-sm">원산지 <span style="color: red">*</span></div>
      <b-form-select id="basicSelectDisabled"
        :plain="true"
        :options="[
          { text: '원산지를 선택해주세요.', country_code: null },
          ...this.origins
            .map(i => ({ text: i.country_en, value: i.country_code }))
            .sort((a, b) => a.text < b.text ? -1 : a.text === b.text ? 0 : 1),
          ]"
        v-model="form.add.country_code">
      </b-form-select>

      <!-- 뱃지 미리보기 -->
<!--      <template v-for="brandType in brandTypeEnabled.map(e => $C.BRAND_TYPE_MAP[e])">
        <span class="badge" :style="`color: ${brandType.color}; background-color: ${brandType.bgColor}`">{{brandType.char}}</span>
      </template>-->

      <template v-slot:modal-footer="{cancel}">
        <b-button variant="success" @click="addBrand()">
          추가
        </b-button>
        <b-button variant="primary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>

    <b-modal title="브랜드 관리 (사용 -> 미사용)" size="lg" v-model="modal.disable">
      <h6>[브랜드 미사용 처리 시]</h6>
      <ul>
        <li>발란몰 내 주문 상세와 위시리스트 목록에 추가된 상품은 지속 조회 가능합니다.</li>
        <li>발란몰 내 고객의 브랜드 위시리스트에서 삭제됩니다.</li>
        <li>FEED를 통한 상품 수집 시 미매칭 브랜드로 노출될 수 있습니다.</li>
        <li>상품은 삭제 처리되며 삭제된 상품은 복구할 수 없습니다.</li>
        <li>상품 삭제 후에도 남아있는 발란몰 상품은 주문, 쇼핑백, 위시리스트 등에 연결된 상품으로, Live 되지는 않습니다.</li>
      </ul>
<!--      <h6>- 브랜드 삭제시 관련 상품도 삭제됩니다. 상품은 백그라운드에서 순차적으로 삭제됩니다.</h6>
      <h6>- 브랜드 삭제시, 주문이 발생했거나 위시리스트 등에 추가된 상품 등은 지속 조회 가능합니다.</h6>
      <h6>- 브랜드 삭제시, 고객의 브랜드 위시리스트, HUB 의 브랜드 Preset, Partner 의 브랜드 즐겨찾기 에서도 삭제됩니다.</h6>
      <h6>- 삭제된 브랜드의 상품은 FEED 에서 재수집되지 않도록 수집제외 브랜드로 편입됩니다.</h6>
      <h6>- 현재 브랜드와 연관된 데이터는 아래와 같습니다.</h6>-->

      <h6>[현재 브랜드와 연관된 데이터]</h6>
      <div v-if="busy.brandStat" class="text-center">
        <b-spinner variant="success"></b-spinner>
      </div>
      <template v-else>
        <b-row>
          <b-col class="text-center">
            <small>Confirmed(미종료)</small>
            <div class="fs-20">{{form.stat.confirmed}}</div>
          </b-col>
          <b-col class="text-center">
            <small><a :href="`/#/goods?$brand=${form.disable.brand_no}&stock_status=normal&display_status=view`" target="_blank">Confirmed Live</a></small>
            <div class="fs-20">{{form.stat.confirmed_live}}</div>
          </b-col>
          <b-col class="text-center">
            <small><a :href="`/#/goods?$brand=${form.disable.brand_no}&goods_status=terminated&display_status=ALL&stock_status=ALL`" target="_blank">Confirmed Terminated</a></small>
            <div class="fs-20">{{form.stat.confirmed_terminated}}</div>
          </b-col>
          <b-col class="text-center">
            <small>Mapped</small>
            <div class="fs-20">{{form.stat.mapped}}</div>
          </b-col>
          <b-col class="text-center">
            <small><a :href="`/#/terminated?$brand=${form.disable.brand_no}`" target="_blank">종료된 상품</a></small>
            <div class="fs-20">{{form.stat.terminated}}</div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <small>발란몰 상품</small>
            <div class="fs-20">{{form.stat.gd_goods}}</div>
          </b-col>
          <b-col class="text-center">
            <small>발란몰 Live 상품</small>
            <div class="fs-20">{{form.stat.gd_goods_live}}</div>
          </b-col>
          <b-col class="text-center">
            <small>발란몰 삭제된 상품</small>
            <div class="fs-20">{{form.stat.gd_goods_removed}}</div>
          </b-col>
          <b-col class="text-center">
            <small>GM(카탈로그)</small>
            <div class="fs-20">{{form.stat.gm}}</div>
          </b-col>
          <b-col class="text-center">
            <small>브랜드 위시리스트</small>
            <div class="fs-20">{{form.stat.bl_designer_wish}}</div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <small>주문</small>
            <div class="fs-20">{{form.stat.orders}}</div>
          </b-col>
          <b-col class="text-center">
            <small>실주문</small>
            <div class="fs-20">{{form.stat.orders_actual}}</div>
          </b-col>
          <b-col class="text-center">
            <small>리뷰</small>
            <div class="fs-20">{{form.stat.bl_review}}</div>
          </b-col>
          <b-col class="text-center">
            <small>HUB 브랜드 preset</small>
            <div class="fs-20">{{form.stat.brand_preset}}</div>
          </b-col>
          <b-col class="text-center">
            <small>Partner 브랜드 즐겨찾기</small>
            <div class="fs-20">{{form.stat.favorite}}</div>
          </b-col>
        </b-row>
      </template>

      <div class="mt-3">
        <b-form-checkbox v-model="form.disable.goodsCheck" class="text-danger">
          [{{form.disable.brand_no}}. {{form.disable.brand_nm_kr}}] 의 상품이 삭제되며 복구할 수 없음을 이해했습니다.
        </b-form-checkbox>
        <b-form-checkbox v-model="form.disable.finalCheck">브랜드 미사용 처리를 진행하겠습니다.</b-form-checkbox>
      </div>

      <template v-slot:modal-footer="{cancel}">
        <b-button variant="danger" @click="disableBrand()" :disabled="!(form.disable.goodsCheck && form.disable.finalCheck) || busy.disableBrand">
          진행
          <b-spinner class="ml-1" small v-if="busy.disableBrand"></b-spinner>
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>


    <b-modal title="브랜드 관리 (미사용 -> 사용)" size="lg" v-model="modal.enable">
      <h6>[브랜드 사용 처리 시]</h6>
      <ul>
        <li>미사용 처리 당시 삭제된 상품은 원복 되지 않습니다.</li>
        <li>해당 브랜드 번호로 FEED를 통한 상품 수집이 가능해집니다.</li>
        <li>해당 브랜드 번호로 파트너센터를 통한 상품 등록이 가능해집니다.</li>
      </ul>

      <h6>[현재 브랜드와 연관된 데이터]</h6>
      <div v-if="busy.brandStat" class="text-center">
        <b-spinner variant="success"></b-spinner>
      </div>
      <template v-else>
        <b-row>
          <b-col class="text-center">
            <small>Confirmed(미종료)</small>
            <div class="fs-20">{{form.stat.confirmed}}</div>
          </b-col>
          <b-col class="text-center">
            <small><a :href="`/#/goods?$brand=${form.enable.brand_no}&stock_status=normal&display_status=view`" target="_blank">Confirmed Live</a></small>
            <div class="fs-20">{{form.stat.confirmed_live}}</div>
          </b-col>
          <b-col class="text-center">
            <small><a :href="`/#/goods?$brand=${form.enable.brand_no}&goods_status=terminated&display_status=ALL&stock_status=ALL`" target="_blank">Confirmed Terminated</a></small>
            <div class="fs-20">{{form.stat.confirmed_terminated}}</div>
          </b-col>
          <b-col class="text-center">
            <small>Mapped</small>
            <div class="fs-20">{{form.stat.mapped}}</div>
          </b-col>
          <b-col class="text-center">
            <small><a :href="`/#/terminated?$brand=${form.enable.brand_no}`" target="_blank">종료된 상품</a></small>
            <div class="fs-20">{{form.stat.terminated}}</div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <small>발란몰 상품</small>
            <div class="fs-20">{{form.stat.gd_goods}}</div>
          </b-col>
          <b-col class="text-center">
            <small>발란몰 Live 상품</small>
            <div class="fs-20">{{form.stat.gd_goods_live}}</div>
          </b-col>
          <b-col class="text-center">
            <small>발란몰 삭제된 상품</small>
            <div class="fs-20">{{form.stat.gd_goods_removed}}</div>
          </b-col>
          <b-col class="text-center">
            <small>GM(카탈로그)</small>
            <div class="fs-20">{{form.stat.gm}}</div>
          </b-col>
          <b-col class="text-center">
            <small>브랜드 위시리스트</small>
            <div class="fs-20">{{form.stat.bl_designer_wish}}</div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <small>주문</small>
            <div class="fs-20">{{form.stat.orders}}</div>
          </b-col>
          <b-col class="text-center">
            <small>실주문</small>
            <div class="fs-20">{{form.stat.orders_actual}}</div>
          </b-col>
          <b-col class="text-center">
            <small>리뷰</small>
            <div class="fs-20">{{form.stat.bl_review}}</div>
          </b-col>
          <b-col class="text-center">
            <small>HUB 브랜드 preset</small>
            <div class="fs-20">{{form.stat.brand_preset}}</div>
          </b-col>
          <b-col class="text-center">
            <small>Partner 브랜드 즐겨찾기</small>
            <div class="fs-20">{{form.stat.favorite}}</div>
          </b-col>
        </b-row>
      </template>

      <div class="mt-3">
        <b-form-checkbox v-model="form.enable.finalCheck">[{{form.enable.brand_no}}. {{form.enable.brand_nm_kr}}] 브랜드의 사용 처리를 진행하겠습니다.</b-form-checkbox>
      </div>

      <template v-slot:modal-footer="{cancel}">
        <b-button variant="primary" @click="enableBrand()" :disabled="!form.enable.finalCheck || busy.enableBrand">
          진행
          <b-spinner class="ml-1" small v-if="busy.enableBrand"></b-spinner>
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import xlsx from '@/views/modules/Xlsx.vue'
import xlsxUploadModal from './xlsx/BrandUpload.vue';

export default {
  name: "BrandList",
  components: {xlsx, xlsxUploadModal},
  data() {
    return {
      fields: {
        list: [
          {key: 'selected', class: 'w-65px'},
          {key: 'brand_no', label: '브랜드 번호', class: 'text-center w-110px', sortable: true},
          {key: 'brand_nm', label: '브랜드명(영문)', class: 'text-center', sortable: true},
          {key: 'brand_nm_kr', label: '브랜드명(한글)', class: 'text-center', sortable: true},
          {key: 'html2', label: '브랜드속성', class: 'text-center', sortable: true},
          {key: 'country_en', label: '원산지(영문)', class: 'text-center', sortable: true},
          {key: 'country_kor', label: '원산지(한글)', class: 'text-center', sortable: true},
          {key: 'html1', label: '사용여부', class: 'text-center', sortable: true},
          {key: '_cdt', label: '최초생성일시', class: 'text-center', sortable: true},
          {key: '_mdt', label: '최근수정일시', class: 'text-center', sortable: true},
        ]
      },
      form: {
        list: {
          search: '',
          brand: [],
          disabled: 'ALL',
          brand_type: 'ALL',
        },
        add: {
          brand_nm: '',
          brand_nm_kr: '',
          brand_type: '',
          country_code: null,
        },
        detail: {
          brand_no: 0,
          brand_nm: '',
          brand_nm_kr: '',
          brand_type: '',
          country_code: '',
        },
        stat: {},
        disable: {
          brand_no: '',
          brand_nm_kr: '',
          goodsCheck: false,
          finalCheck: false
        },
        enable: {
          brand_no: '',
          brand_nm_kr: '',
          finalCheck: false
        },
      },
      lastBody: {list: {}},
      items: {list: []},
      busy: {list: false, addBrand: false, brandStat: false, disableBrand: false, enableBrand: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      modal: {add: false, disable: false, enable: false, xlsxUpload: false, detail: false},
      perPage: 20,
      brandTypeEnabled: ['luxury', 'contemporary', 'golf', 'beauty', 'kids', 'living', 'sports', 'digital', 'pet', 'art', 'etc'],

      selectedItem: null,

      xlsx: {
        selected: [],
        keys: ['brand_no', 'brand_nm', 'brand_nm_kr', 'brand_type_kr', 'country_en', 'country_kor', 'disabled_str', '_cdt', '_mdt'],
        labels: ['브랜드 번호', '브랜드명(영문)', '브랜드명(한글)', '브랜드속성', '원산지(영문)', '원산지(한글)', '사용여부', '최초생성일시', '최근수정일시'],
      },

      origins: [],
    };
  },
  async created() {
    if (this.$R('META_BRAND_X')) {
      this.fields.list.push({
        key: '_actions', label: '관리', class: 'text-center w-130px', buttons: [
          {label: '상세', variant: 'secondary', event: 'detail'},
          {label: '관리', variant: 'primary', event: 'manage'},
        ]
      });
    } else {
      this.fields.list.push({
        key: '_actions', label: '관리', class: 'text-center w-75px', buttons: [
          {label: '상세', variant: 'secondary', event: 'detail'}
        ]
      });
    }
    this.$utils.getStatus(this.$options.name, this, 'perPage');
    this.list();

    let originResp = await this.$api.getJson('/meta/originMapping/mappingOrigin');
    this.origins = originResp.hotItems;
  },
  methods: {
    copyText(e) {
      let res = this.$utils.copyToClipboard(e);
      if (res) this.$alertTop(`"${e}" 로 복사되었습니다`);
    },
    async list(more) {
      const form = this.form.list;
      return this.$api.postTable(this, '/meta/brand', {
          search: form.search,
          disabled: form.disabled,
          brand_type: form.brand_type,
          brandNos: form.brand.map(e => e.brand_no)
        },
        {more, fnAssign: this.assignTableData});
    },
    assignTableData(e) {
      const enabled = this.brandTypeEnabled.includes(e.brand_type);
      e.html1 = e.disabled ? `<span class="badge badge-danger">미사용</span>` : `<span class="badge badge-success">사용</span>`;
      e.html2 = enabled ?
        `<span class="badge badge-light">${this.$C.BRAND_TYPE_MAP[e.brand_type].name}</span>` :
        `<span class="badge badge-danger">${e.brand_type ? '수정필요' : '입력필요'}</span>`;
      e.disabled_str = e.disabled ? '미사용' : '사용';
      e.brand_type_kr = enabled ? this.$C.BRAND_TYPE_MAP[e.brand_type].name : e.brand_type ? '수정필요' : '';
      return e;
    },
    showAddModal() {
      this.modal.add = true;
      this.form.add.brand_nm = '';
      this.form.add.brand_nm_kr = '';
      this.form.add.brand_type = '';
      this.form.add.country_code = null;
    },
    async addBrand() {
      if (!this.form.add.brand_nm) return alert('브랜드명(영문)을 입력해주세요');
      if (!this.form.add.brand_nm_kr) return alert('브랜드명(한글)을 입력해주세요');
      if (!this.form.add.country_code) return alert('원산지를 입력해주세요');

      this.modal.add = false;
      this.busy.addBrand = true;
      const j = await this.$api.postJson('/meta/brand/add', {...this.form.add});
      this.busy.addBrand = false;
      if (j) {
        this.list();
      }
    },
    preDown() {
      this.xlsx.selected = this.items.list.filter(e => e.selected);
      if (!this.xlsx.selected.length) return alert('다운받을 브랜드를 선택해주세요');
      return true;
    },
    btnAction(row, event) {
      if (event === 'detail') {
        this.modal.detail = true;
        this.selectedItem = row.item;
        this.form.detail.brand_no = row.item.brand_no;
        this.form.detail.brand_nm = row.item.brand_nm;
        this.form.detail.brand_nm_kr = row.item.brand_nm_kr;
        this.form.detail.brand_type = row.item.brand_type;
        this.form.detail.country_code = row.item.country_code;
        this.brandStat(row.item.brand_no);
      } else if (event === 'manage') {
        if (row.item.disabled) { // 미사용 -> 사용
          this.modal.enable = true;
          this.form.enable.brand_no = row.item.brand_no;
          this.form.enable.brand_nm_kr = row.item.brand_nm_kr;
          this.form.enable.finalCheck = false;
          this.brandStat(row.item.brand_no);
        } else { // 사용 -> 미사용
          this.modal.disable = true;
          this.form.disable.brand_no = row.item.brand_no;
          this.form.disable.brand_nm_kr = row.item.brand_nm_kr;
          this.form.disable.goodsCheck = false;
          this.form.disable.finalCheck = false;
          this.brandStat(row.item.brand_no);
        }
      }
    },
    async brandStat(brandNo) {
      this.busy.brandStat = true;
      const j = await this.$api.postJson('/meta/brand/stat', {brandNo});
      this.busy.brandStat = false;
      if (j) {
        this.form.stat = j.stat;
      }
    },
    async disableBrand() {
      if (!'goods,final'.split(',').every(e => this.form.disable[e + 'Check'])) return alert('체크사항을 확인해주세요');
      this.busy.disableBrand = true;
      const j = await this.$api.postJson('/meta/brand/disable', {...this.form.disable});
      this.busy.disableBrand = false;
      if (j) {
        this.modal.disable = false;
        this.$utils.alert('브랜드가 미사용 처리되었습니다. 상품은 백그라운드에서 순차적으로 삭제됩니다.');
        this.list();
      }
    },
    async enableBrand() {
      if (!this.form.enable.finalCheck) return alert('체크사항을 확인해주세요');
      this.busy.enableBrand = true;
      const j = await this.$api.postJson('/meta/brand/enable', {...this.form.enable});
      this.busy.enableBrand = false;
      if (j) {
        this.modal.enable = false;
        this.$utils.alert('브랜드가 사용 처리되었습니다.');
        this.list();
      }
    },
    async saveBrand() {
      console.log(this.form.detail);

      const j = await this.$api.postJson('/meta/brand/update', {
        ...this.form.detail,
      });

      if (j) {
        this.modal.detail = false;
        this.$utils.alert('브랜드가 업데이트 되었습니다.');
        this.list();
      }
    },
  }
}
</script>
